<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <h3 class="inBlock">角色列表</h3>
    </div>
    <div class="box">
      <div class="btnall">
        <el-button
          @click="dialogroleShow = true"
          size="medium"
          type="primary"
          plain
          >添加 <i class="el-icon-plus"></i
        ></el-button>
      </div>

      <el-table
        row-key="id"
        :tree-props="tableprops"
        border
        :data="list"
        :row-style="{ height: '30px', padding: '0' }"
        default-expand-all
      >
        <!-- <el-table-column type="expand"> 123 </el-table-column> -->
        <el-table-column prop="id" label="id"></el-table-column>
        <el-table-column prop="name" label="角色名称"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column prop="status" label="状态">
          <div slot-scope="scope">
            <el-tag
              v-if="scope.row.status === 1"
              size="mini"
              effect="dark"
              type="success"
              >已启用</el-tag
            >
            <el-tag v-else size="mini" effect="dark" type="danger"
              >已禁用</el-tag
            >
          </div>
        </el-table-column>
        <el-table-column label="操作">
          <div slot-scope="scope">
            <el-button
              @click="setrole(scope.row)"
              size="mini"
              type="primary"
              icon="el-icon-edit"
              circle
            ></el-button>
            <el-button
              @click="roleauthority(scope.row)"
              size="mini"
              type="warning"
              icon="el-icon-star-off"
              circle
            ></el-button>
            <el-button
              @click="delrole(scope.row)"
              size="mini"
              type="danger"
              icon="el-icon-delete"
              circle
            ></el-button>
          </div>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog title="添加角色" :visible.sync="dialogroleShow" width="28%">
      <el-form ref="formAdd" :rules="rule" :model="formAdd" label-width="80px">
        <el-form-item prop="parent_id" label="父级">
          <el-cascader
            size="mini"
            :placeholder="cascadertext"
            :props="optionProps"
            :options="list"
            @change="setclass"
            ref="cascader"
          ></el-cascader>
        </el-form-item>
        <el-form-item prop="name" label="角色名称">
          <el-input
            style="width: 300px"
            size="mini"
            v-model="formAdd.name"
          ></el-input>
        </el-form-item>

        <el-form-item prop="list_order" label="排序">
          <el-input
            style="width: 350px"
            size="mini"
            v-model="formAdd.list_order"
          ></el-input>
        </el-form-item>
        <el-form-item prop="status" label="状态">
          <el-radio v-model="formAdd.status" :label="1">启用</el-radio>
          <el-radio v-model="formAdd.status" :label="0">禁用</el-radio>
        </el-form-item>
        <el-form-item prop="remark" label="备注">
          <el-input
            style="width: 400px"
            type="textarea"
            size="mini"
            v-model="formAdd.remark"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogroleShow = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加权限 -->
    <el-dialog title="分配权限" :visible.sync="powershow" width="30%">
      <p class="rolename">
        角色名称: <span>{{ rolename }}</span>
      </p>
      <el-tree
        :data="powerlist"
        show-checkbox
        node-key="id"
        default-expand-all
        :default-checked-keys="rolepowerary"
        :props="optionProps"
        ref="treepower"
        @check-change="setrolepower"
      >
      </el-tree>

      <span slot="footer" class="dialog-footer">
        <el-button @click="powershow = false">取 消</el-button>
        <el-button type="primary" @click="AddRolePower">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import {
  roleList,
  addRole,
  roleDelete,
  roleDetail,
  rolePrivileges,
  getrolepower,
} from "@/api/irm.js";
export default {
  components: {},
  name: "",
  data() {
    return {
      powerlist: [], // 权限列表
      rolename: "",
      roleId: null,
      rolepowerary: [], // 已有权限
      cascadertext: "无",
      powershow: false,

      optionProps: {
        value: "id",
        label: "name",
        children: "children",
        checkStrictly: true,
      },
      tableprops: {
        children: "children",
      },
      dialogroleShow: false,
      formlist: {
        name: "",
        status: "",
        page: 1,
        limit: 10,
      },
      list: [],
      formAdd: {
        parent_id: 1,
        status: 1,
        list_order: "",
        name: "",
        remark: "",
      },
      rule: {
        name: [{ required: true, message: "请输入角色名称", trigger: "blur" }],
      },
    };
  },
  watch: {
    dialogroleShow: function (newdata, olddata) {
      if (newdata === false) {
        this.formAdd.parent_id = 1;
        this.formAdd.status = 1;
        this.formAdd.list_order = "";
        this.formAdd.name = "";
        this.formAdd.remark = "";
        delete this.formAdd.id;
      }
    },
    powershow: function (newdata, olddata) {
      if (newdata === false) {
        this.rolepowerary = [];
      }
    },
  },
  methods: {
    powerstatus(ary) {
      ary.forEach((item) => {
        if (!item.children) {
          if (item.is_have_auth == 1) {
            this.rolepowerary.push(item.id);
          }
        } else {
          this.powerstatus(item.children);
        }
      });
    },
    async AddRolePower() {
      let nodesObj = []
      this.$refs["treepower"].getHalfCheckedNodes().forEach(item => {
          nodesObj.push(item.id)
      });
      let nodesObj2 = this.$refs["treepower"].getCheckedKeys();
      console.log([...nodesObj, ...nodesObj2]);
      this.powershow = false;
      const { data } = await rolePrivileges({
        roleId: this.roleId,
        menuId: [...nodesObj, ...nodesObj2],
      });
      if (data.code != 200) return this.$message.error(data.data);
      this.$message.success(data.data);
    },
    setrolepower(data, power) {},

    setclass() {
      let nodesObj = this.$refs["cascader"].getCheckedNodes();
      this.formAdd.parent_id = nodesObj[0].data.id;
    },
    async roleauthority(datas) {
      this.rolename = datas.name;
      this.roleId = datas.id;
      const { data } = await getrolepower({ role_id: datas.id });
      if (data.code != 200) return this.$message.error(data.data);
      this.powerlist = data.data.menu_list;
      this.powerstatus(this.powerlist);
      this.powershow = true;
    },
    setrole(datas) {
      this.formAdd.id = datas.id;
      this.formAdd.parent_id = datas.parent_id;
      this.formAdd.status = datas.status;
      this.formAdd.list_order = datas.list_order;
      this.formAdd.name = datas.name;
      this.formAdd.remark = datas.remark;
      this.dialogroleShow = true;
      this.getDetail(this.formAdd.parent_id);
    },
    async submit() {
      const { data } = await addRole(this.formAdd);
      if (data.code != 200) return this.$message.error(data.data);
      this.$message.success(data.data);
      this.getlist();
      this.dialogroleShow = false;
    },
    async getlist() {
      const { data } = await roleList(this.formlist);
      if (data.code != 200) return this.$message.error(data.data);
      this.list = data.data;
    },
    delrole(datas) {
      this.$confirm("您真的要删除吗?, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await roleDelete({ id: datas.id });
          if (data.code != 200) return this.$message.error(data.data);
          this.getlist();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    async getDetail(id) {
      const { data } = await roleDetail({ id });
      if (data.data.id == 1) {
        this.cascadertext = "无";
      } else {
        this.cascadertext = data.data.name;
      }
    },
  },
  created() {
    this.getlist();
  },
};
</script>

<style scoped lang='less'>
.btnall {
  padding: 10px 20px;
  margin: 20px 0;
  background-color: #f1f3f4;
  border-radius: 10px;
}
.el-table /deep/td {
  padding: 5px 0;
}
.rolename {
  margin: 10px;
  padding: 5px 10px;
  span {
    color: #111;
  }
  background-color: #f4f4f4;
}
</style>